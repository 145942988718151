<template>
  <section class="certificate">
    <div class="certificate__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="goBackView()"
      />
      <h2>Certificados</h2>
      <p class="certificate__container--description">
        Acompanhe os status dos certificados em ambientes homologação e produção
      </p>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div class="certificate__tableBox" v-if="!errorTrigger" v-show="!loadingAPI">
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Tipo</th>
              <th>Versão</th>
              <th>Vencimento</th>
              <th>Aviso</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(setCertificateAPIitems, index) in getCertificateAPI" :key="index">
              <td class="cellStatus">
                <p :style="{'background-color' : colorStatus[index]}">{{ setCertificateAPIitems.environment }}</p>
              </td>
              <td><a href="/certificados/detalhes-certificado">{{ setCertificateAPIitems.type }}</a></td>
              <td>{{ setCertificateAPIitems.version }}</td>
              <td>{{ setCertificateAPIitems.maturityDate }}</td>
              <td>{{ setCertificateAPIitems.warningDate }}</td>
            </tr>
            <!-- <tr>
              <td class="cellStatus"><p style="background-color:#0063F7; color:white">Ativo</p></td>
              <td><a href="/certificados/detalhes-certificado">BRCAC</a></td>
              <td>0.0.1</td>
              <td>05/01/23</td>
              <td>05/01/23</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import apiDefault from '@/services/api.js'
import colorsExported from '@/styles/exports.module.scss'

export default {
  name: 'CertificatePage',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',

      colorStatus: [],
      getCertificateAPI: [],

    }
  },
  components: {
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    goBackView(){
      history.back();
    },
    setLoadingErrorDefault(){
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      
      setTimeout(() => {
        this.getDataCertificateAPI();
      }, '1000');
    },
    // Get Data API
    getDataCertificateAPI(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/certificate`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getCertificateAPI = resp.data;
        if(this.getCertificateAPI == 204){
          this.loadingAPI = false;
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else{
          this.loadingAPI = false;
          this.errorTrigger = false;
          this.setColorStatus();
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    setColorStatus(){
      const byStatusColor = []
      this.getCertificateAPI.forEach((certificate) =>{
        byStatusColor.push(certificate.environment)
        this.colorCertificateByStatus = byStatusColor
      });
      const arraybyStatus = [];
      this.colorCertificateByStatus.forEach(colorByStatus => {
        switch(colorByStatus){
          case 'Produção': 
            arraybyStatus.push(colorsExported.colorSuccess);
            break;
          case 'Homologação': 
            arraybyStatus.push(colorsExported.colorWarning);
            break;
          case 'Ativo Azul': 
            arraybyStatus.push(colorsExported.colorInfo);
            break;
          default:
            arraybyStatus.push(colorsExported.colorDanger);
            break;
        }
        this.colorStatus = arraybyStatus;
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.certificate{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      border-bottom: 1px solid $color-background-table-line;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
      padding: 0 0 20px;
    }
  }
  &__tableBox{
    display: block;
    thead, tbody, &--tableTotal{
      border-right: 1px solid $color-background-table-line;
      background-color: $color-background-table;
      width: 100%;
      th, td{
        border-left: 1px solid $color-background-table-line;
        border-bottom: 1px solid $color-background-table-line;
        font-size: 14px;
        font-weight: 400;
        width: 10%;
        margin: 0;
        padding: 8px;
        vertical-align: middle;
        text-align: center;
      }
    }
    thead{
      border-top: 1px solid $color-background-table-line;
      th{
        font-weight: 600;
        height: 50px;
      }
    }
    tbody{
      tr{
        &:nth-child(odd){
          background-color: white;
        }
        .cellStatus{
          height: 36px;
          padding: 3px;
          p{
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
