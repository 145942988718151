<template>
  <section class="loginUser">
    <LoadingBox v-show="loadingAPI"/>
    <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
    <div class="loginUser__container" v-show="!loadingAPI" v-if="!errorTrigger">
      <!-- MAIN LOGIN -->
      <form @submit.prevent="checkLogin()" class="loginUser__container__login" v-show="isLogin">
        <h2>
          Entre com o seu e-mail e senha
        </h2>
        <span v-show="loginErrorMsg" class="loginUser__container__errorMsg">
          E-mail ou senha incorretos.
        </span>
        <label class="loginUser__container--text">E-MAIL</label>
        <input autofocus type="email" v-model="loginEmail" name="email" placeholder="name@host.com" autocomplete="username" required >
        <label class="loginUser__container--text">SENHA</label>
        <input type="password" v-model="loginPassword" name="password" autocomplete="current-password" placeholder="******" required >
        <p class="loginUser__container--text textForgot" @click="goForgotPass()">
          Esqueceu sua senha?
        </p>
        <ButtonShape
          textButton="Entrar"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>

      <!-- TOKEN -->
      <form @submit.prevent="" class="loginUser__container__tokenAuth" v-show="isTokenAuth">
        <h2>
          Token de Autenticação
        </h2>
        <p>Informe o Token fornecido no seu aplicativo de autenticação para validá-lo.</p>
        <span v-show="isTokenError" class="loginUser__container__errorMsg">
          Token inválido!<br>
          Por favor, insira o token correto.
        </span>
        <span v-show="isTokenErrorNoSession" class="loginUser__container__errorMsg">
          Sua sessão expirou!<br>
          Por favor, faça o login novamente.
        </span>
        <label class="loginUser__container__tokenAuth--text">TOKEN</label>
        <input id="idTokenRecover" @keyup.enter="tokenVerify()" autofocus type="text" v-model="tokenRecover" name="tokenRecover" placeholder="000000" >
        <div class="loginUser__container--doubleLine">
          <ButtonShape
            textButton="Validar"
            :bgButtonColor="bgButtonColor"
            @click="tokenVerify()"
          />
          <ButtonShape
            :classLine="true"
            textButton="Recadastrar"
            :bgButtonColor="bgButtonColor"
            @click="[isRecoveryToken = true, isTokenAuth = false]"
          />
        </div>
      </form> 

      <!-- RECOVERY TOKEN -->
      <form @submit.prevent="recoveryToken()" class="loginUser__container__tokenAuth" v-show="isRecoveryToken">
        <ButtonArrowCircle
          :buttonArrowInvert="true"
          class="btnBack"
          @click="$router.push('/')"
        />
        <h2>
          Recadastrar Token
        </h2>
        <p class="loginUser__container--text">
          Delete seu Token atual em seu aplicativo de autenticação.
        </p>
        <img src="@/assets/images/icon_tokenRecovery.svg" alt="token">
        <ButtonShape
          textButton="Próximo"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>

      <!-- CODE RECOVERY TOKEN -->
      <form @submit.prevent="codeRecoveryToken()" class="loginUser__container__tokenAuth" v-show="isCodeRecoveryToken">
        <ButtonArrowCircle
          :buttonArrowInvert="true"
          class="btnBack"
          @click="$router.push('/')"
        />
        <h2>
          Recadastrar Token
        </h2>
        <p class="loginUser__container--text">
          Enviamos um código para redefinir seu token no e-mail {{getEmailTokenForgot}}.<br>
          Escreva o código recebido para criar um novo.
        </p>
        <span v-show="isTokenErrorNoSession" class="loginUser__container__errorMsg">
          Sua sessão expirou!<br>
          Por favor, faça o login novamente.
        </span>
        <label class="loginUser__container--text">CÓDIGO</label>
        <input type="number" v-model="codeTokenRecover" name="codeTokenRecover" placeholder="000000">
        <span v-if="recoverCodeErrorMsg" class="loginUser__container__errorMsg">
          O código está incorreto!
        </span>
        
        <ButtonShape
          textButton="CONFIRMAR"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>

      <!-- TOKEN QRCode-->
      <form @submit.prevent="goToTokenVerify()"
        class="loginUser__container__tokenAuth--qrcode"
        v-show="isTokenQRCode">
        <h2>
          Token de Autenticação
        </h2>
        <p class="loginUser__container__tokenAuth--text">
          Abra um aplicativo <strong>Autenticador</strong>, utilize a câmera do seu dispositivo móvel e capture o código QR abaixo para ativação do Token.
        </p>
        <div class="loginUser__container--doubleLine">
          <img src="@/assets/images/icon_tokenAuth.svg" alt="QR Code">
          <canvas id="qrCodeImg"></canvas>
        </div>
        <ButtonShape
          textButton="Informar TOKEN"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>

      <!-- FORGOT PASSWORD -->
      <form @submit.prevent="goRecoverPass()" class="loginUser__container__forgotPassword" v-show="isForgot">
        <h2>
          Esqueceu sua senha?
        </h2>
        <p class="loginUser__container--text">
          Escreva seu e-mail abaixo e vamos te mandar uma mensagem para redefinir sua senha
        </p>
        <span v-show="recoverErrorMsg" class="loginUser__container__errorMsg">
          E-mail incorreto ou não cadastrado.
        </span>
        <label class="loginUser__container--text">E-MAIL</label>
        <input autofocus type="email" v-model="emailRecover" name="emailRecover" placeholder="name@host.com" required >
        <ButtonShape
          textButton="RECUPERAR MINHA SENHA"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>

      <!-- RECOVER PASSWORD -->
      <form @submit.prevent="`${recoverPass ? checkRecoverPass() : authChallenge()}`"
        class="loginUser__container__recoverPassword"
        v-show="isRecover"
      >
        <h2>
          {{ recoverPass ? 'Recuperar Senha' : 'Altere sua senha' }}
        </h2>
        <p v-show="recoverPass" class="loginUser__container--text">
          Enviamos um código para redefinir sua senha no e-mail {{getUserForgot}}.<br>
          Escreva o código recebido para criar uma nova.
        </p>
        <span v-show="isTokenErrorNoSession" class="loginUser__container__errorMsg">
          Sua sessão expirou!<br>
          Por favor, faça o login novamente.
        </span>
        <label v-if="recoverPass" class="loginUser__container--text">CÓDIGO</label>
        <input v-if="recoverPass" type="number" v-model="codeRecover" name="codeRecover" placeholder="000000" required >
        <span v-if="recoverCodeErrorMsg" class="loginUser__container__errorMsg">
          O código está incorreto!
        </span>
        
        <label class="loginUser__container--text">NOVA SENHA</label>
        <input
          autofocus
          type="password"
          v-model="passwordRecover"
          name="passwordRecover"
          placeholder="Nova senha"
          autocomplete="new-password"
          v-on:keyup="checkPassMust()"
          required >
        
        <label class="loginUser__container--text">CONFIRME A NOVA SENHA</label>
        <input
          type="password"
          v-model="passwordRecoverConfirm"
          name="passwordRecoverConfirm"
          placeholder="Confirme a nova senha"
          autocomplete="new-password"
          v-on:keyup="checkPassMust()"
          required >
        <div class="loginUser__container__recoverPassword--mustPass">
          <p :class="mustPassLower ? 'checkMustPass':''">
            <img v-show="!mustPassLower" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassLower" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            Ter ao menos uma letra minúscula
          </p>
          <p :class="mustPassUpper ? 'checkMustPass':''">
            <img v-show="!mustPassUpper" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassUpper" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            Ter ao menos uma letra maiúscula
          </p>
          <p :class="mustPassNumber ? 'checkMustPass':''">
            <img v-show="!mustPassNumber" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassNumber" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            Possuir pelo menos um número
          </p>
          <p :class="mustPassLength ? 'checkMustPass':''">
            <img v-show="!mustPassLength" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassLength" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            Conter no mínimo 8 caracteres
          </p>
          <p :class="mustPassSpecial ? 'checkMustPass':''">
            <img v-show="!mustPassSpecial" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassSpecial" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            Apresentar pelo menos um caractere especial
          </p>
          <p :class="mustPassMatch ? 'checkMustPass':''">
            <img v-show="!mustPassMatch" src="@/assets/images/icon_mustPassNot.svg" alt="erro senha">
            <img  v-show="mustPassMatch" src="@/assets/images/icon_mustPassCheck.svg" alt="check senha">
            As senhas são iguais
          </p>
        </div>
        <ButtonShape
          :class="passwordRecoverVerify ? 'disable' : ''"
          :textButton="recoverPass ? 'REDEFINIR SENHA' : 'CONFIRMAR'"
          :bgButtonColor="bgButtonColor"
          :btnDisabled="!passwordRecoverVerify"
          @click="prevent"
        />
      </form>
    </div>
  </section>
</template>

<script>
// import { ref } from "vue"; 
import apiDefault from '@/services/api.js'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import { useUserDataStore } from '@/stores/UserStore'
import QRCode from 'qrcode'

export default {
  name: 'LoginUser',
  data(){
    return {
			loginEmail: '',
      loginPassword: '',
      hiddenPass: false,
      loadingAPI: false,
      errorTrigger: false,
      isErrorFlag: false,
      loginErrorMsg: false,
      bgButtonColor: colorsExported.colorPrimary,

      isTokenAuth: false,
      isTokenQRCode: false,
      isLogin: true,
      isForgot: false,
      isRecover: false,
      recoverErrorMsg: false,
      recoverCodeErrorMsg: false,
      isTokenError: false,
      isTokenErrorNoSession: false,
      isEmailRecoveryToken: false,
      isRecoveryToken: false,
      
      tokenRecover: '',
      emailRecover: '',
      recoverPass: true,

      getDataUser: [],

      getDataTokenVerify: '',
      getDataUpdateAuth: '',
      statusAuth: '',

      getUserForgot: [],
      setUserForgot: [],
      codeRecover: '',
      passwordRecover: '',
      passwordRecoverConfirm:'',
      passwordRecoverVerify: false,
      mustPassLower: false,
      mustPassUpper: false,
      mustPassNumber: false,
      mustPassLength: false,
      mustPassSpecial: false,
      mustPassMatch: false,

      userGroupsName: '',

      // STORE
      userDataStore: [],
    }
  },
  props: {
  },
  components: {
    ButtonShape,
    ButtonArrowCircle,
    ErrorPages,
    LoadingBox,
  },
  watch:{
    // $route(){
    //   this.onChangeRouteLogin();
    // }
	},
  // mounted() {
    
  // },
  methods: {
    checkLogin(){
    // function to set up all the skins of the login as false.
    // Just loading to show and go to function loginApiCognito() who calls API 'oauth2/login'.

      this.loadingAPI = true;
      this.isTokenAuth = false;
      this.isLogin = false;
      this.isForgot = false;
      this.isRecover = false;
      this.loginApiCognito();
    },
    loginApiCognito(){
    // function who calls API 'oauth2/login'
    // IF is the 1st access, that goes to Recovery Password view call by goForgotPass();
    // -- checking if the ChallengeName == NEW_PASSWORD_REQUIRED
    // IF is normal access, that goes to autentication view call by setting true to isTokenAuth;
    // -- checking if the ChallengeName == 'SOFTWARE_TOKEN_MFA'
    // IF the Status Authentication is "False" (as a String) that send sends a email to gets a recovery code;
    // -- setting isEmailRecoveryToken to true.

      apiDefault.post(`oauth2/login`,{
        "username":`${this.loginEmail}`,
        "password":`${this.loginPassword}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setDataAPILogin = resp.data;
        this.getDataUser = setDataAPILogin 
        this.loadingAPI = false;
        if(this.getDataUser.statusCode == 404){
          this.loginErrorMsg = true
          this.isLogin = true;
        }else{
          if(this.getDataUser.ChallengeName == 'NEW_PASSWORD_REQUIRED'){
            this.emailRecover = this.loginEmail
            this.goForgotPass();
          }else if(this.getDataUser.ChallengeName == 'SOFTWARE_TOKEN_MFA'){
            this.isTokenQRCode = false;
            this.isTokenAuth = true;
          }else if(this.statusAuth == "False"){
            this.loadingAPI = false;
            this.isCodeRecoveryToken = true;
            this.emailRecoveryToken();
          }else{
            this.isTokenAuth = false;
            this.tokenConfig();
          }
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    authChallenge(){
    // function who calls API 'oauth2/auth_challenge'
    // It gets the User Session setting into the getDataUser.Session

      apiDefault.post('oauth2/auth_challenge',{
        "session":`${this.getDataUser.Session}`,
        "new_password":`${this.passwordRecover}`,
        "username":`${this.loginEmail}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setDataAuthChallenge = resp.data;
        this.getDataAuthChallenge = setDataAuthChallenge
        this.loadingAPI = false;
        if(this.getDataAuthChallenge.statusCode == 404){
          if(this.getDataAuthChallenge.errorMsg == "Invalid attributes given, name is missing"){
            this.isTokenErrorNoSession = true;
            setTimeout(() => {
              this.$router.push('/');
            }, 2000);
          }else{
            this.$router.push('/');
          }
        }else{
          this.getDataUser.Session = this.getDataAuthChallenge.Session
          this.loginPassword = this.passwordRecover;
          this.loadingAPI = true;
          this.isRecover = false;
          this.loginApiCognito();
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    tokenVerify(){
    // function who calls API 'oauth2/verify_token'
    // It gets the User whole data and setting into 'getDataUser'
    // And calls setLocalDataUser() to set up al User data to global.

      this.loadingAPI = true;
      apiDefault.post('oauth2/verify_token',{
        [this.getDataUser.ChallengeName ? 'session' : 'accessToken'] : `${this.getDataUser.ChallengeName ? `${this.getDataUser.Session}` : `${this.getDataUser.AuthenticationResult.AccessToken}`}`,
        "userCode":`${this.tokenRecover.toString()}`,
        "username":`${this.loginEmail}`,
        "ChallengeName" : `${this.getDataUser.ChallengeName ? `${this.getDataUser.ChallengeName}` : ""}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setDataTokenVerify = resp.data;
        this.getDataTokenVerify = setDataTokenVerify
        this.loadingAPI = false;
        if(this.getDataTokenVerify.statusCode == 404){
          if(this.getDataTokenVerify.errorMessage === "Invalid session for the user, session is expired."){
            this.isTokenError = false
            this.isTokenErrorNoSession = true;
            setTimeout(() => {
              this.$router.push('/');
            }, 3000);
          }else{
            this.isTokenError = true
            this.isTokenErrorNoSession = false;
          }
        }else{
          this.getDataUser = this.getDataTokenVerify
          this.setLocalDataUser();
        }
      }).catch(error => {
        this.loadingAPI = false;
        if(error.response.status == "502"){
          this.isTokenError = true
        }else{        
          this.isTokenAuth = false;
          this.isErrorFlag = true;
          this.errorTrigger = true;
          console.log('erro: ', error)
        }
      })
    },
    goToTokenVerify(){
    // its called after the User scan the QR code, and click the button to validate.
      this.isTokenQRCode = false;
      this.isTokenAuth = true 
    },
    tokenConfig(){
    // function who calls API 'oauth2/config_token'
    // It gets the Users Session and Secret Code
    // to make the 'otpauth', to creates the image of the QRCode

      apiDefault.post(`oauth2/config_token`,{
        "accessToken":`${this.getDataUser.AuthenticationResult.AccessToken}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        if(this.getDataTokenVerify.statusCode == 404){
          if(this.getDataTokenVerify.errorMessage === "Invalid session for the user."){
            this.isTokenError = false
            this.isTokenErrorNoSession = true;
            setTimeout(() => {
              this.$router.push('/');
            }, 5000);
          }else{
            this.isTokenError = true
            this.isTokenErrorNoSession = false;
          }
        }else{
          let setDataTokenConfig = resp.data;
          this.getDataUser.Session = setDataTokenConfig.Session
          this.getDataUser.SecretCode = setDataTokenConfig.SecretCode
          this.secretQrCode = `otpauth://totp/Portal%20Open%20Finance:${this.loginEmail}?secret=${this.getDataUser.SecretCode}&issuer=Portal%20Open%20Finance&algorithm=SHA1&digits=6`
          this.statusAuth = "True"
          this.updateAuth();
        }
      }).catch(error => {
        this.isTokenQRCode = false;
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    tokenQRCode(){
    // function who creates the image of the QRCode

      QRCode.toCanvas(
        document.getElementById('qrCodeImg'),
        this.secretQrCode, {
          errorCorrectionLevel: 'H',
          toSJISFunc: QRCode.toSJIS,
        },
        this.isTokenQRCode = true,
        this.loadingAPI = false
      )
    },

    // RECOVERY TOKEN
    updateAuth(){
    // function who calls API 'oauth2/update_authenticator'
    // IF the 'statusAuth' have a string (not boolean) calls 'True';
    // -- thats call a function tokenQRCode() whos going to draw the QRCode.
    // IF the 'statusAuth' DO NOT have a string (not boolean) calls 'True';
    // -- thats call a function loginApiCognito() whos going to login the user detaching user and Token.

      apiDefault.post(`oauth2/update_authenticator`,{
        "status" : `${this.statusAuth}`,
        "username":`${this.loginEmail}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(() => {
          this.loadingAPI = true;
          if(this.statusAuth === "True"){
            this.tokenQRCode();
          }else{
            this.loginApiCognito();
          }
      }).catch(error => {
        this.isTokenQRCode = false;
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    recoveryToken(){
    // Function to set statusAuth to a String (not Boolean) "False";
    // And call function updateAuth() to detach User and Token.

      this.isRecoveryToken = false;
      this.loadingAPI = true;
      this.statusAuth = "False";
      this.updateAuth();
    },
    emailRecoveryToken(){
    // function who calls API 'oauth2/send_verification_code'
    // Send a Email to the email thats verified first;
    // Then shows a Input, so the user can insert the code sended by email

      apiDefault.post(`oauth2/send_verification_code`,{
        "accessToken":`${this.getDataUser.AuthenticationResult.AccessToken}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setTokenForgot = resp.data;
        if(setTokenForgot.statusCode == 404){
          this.recoverErrorMsg = true
        }else{
          this.getEmailTokenForgot = setTokenForgot.CodeDeliveryDetails.Destination
          console.log('sdfsdsfd', this.isCodeRecoveryToken)
        }
      }).catch(error => {
          this.isErrorFlag = true;
          this.errorTrigger = true;
          this.loadingAPI = false;
          console.log('erro: ', error)
      })
    
    },
    codeRecoveryToken(){
    // function who calls API 'oauth2/verify_verification_code'
    // hides the token's input verification code
    // Calls the function tokenConfig()

      this.passwordRecoverVerify = true
      apiDefault.post(`oauth2/verify_verification_code`,{
        "accessToken":`${this.getDataUser.AuthenticationResult.AccessToken}`,
        "verificationCode" : `${this.codeTokenRecover}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setUserForgot = resp.data;
        if(setUserForgot.statusCode == 404){
          this.recoverCodeErrorMsg = true
        }else{
          this.loadingAPI = false;
          this.isCodeRecoveryToken = false;
          this.tokenConfig();
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },

    // FORGOT PASSWORD
    goForgotPass(){
    // function to changes the view in 2 cases:
    // -- if is the user access and need to change the password
    // -- if user will set the recovery email to be send up and have the recovery password code .

      this.isLogin = false;
      if(this.getDataUser.ChallengeName == 'NEW_PASSWORD_REQUIRED'){
        this.recoverPass = false;
        this.isRecover = true;
      }else{
        this.isForgot = true;
      }
    },
    goRecoverPass(){
    // function who calls API 'oauth2/forgot_password'
    // Send request with a valid email and gets if the emakil is valid or not,
    // shows the inputs to insert the code and the new password twice

      apiDefault.post(`oauth2/forgot_password`,{
        "username":`${this.emailRecover}`,
      },{
        headers: {
          'accept': 'text/json',
          'Content-Type': 'application/json'
        }
      }
      ).then(resp => {
        let setUserForgot = resp.data;
        if(setUserForgot.statusCode == 404){
          this.recoverErrorMsg = true
        }else{
          this.loadingAPI = false;
          this.isForgot = false;
          this.isRecover = true;
          this.recoverPass = true;
          this.getUserForgot = setUserForgot.CodeDeliveryDetails.Destination
        }
      }).catch(error => {
          this.isErrorFlag = true;
          this.errorTrigger = true;
          this.loadingAPI = false;
          console.log('erro: ', error)
      })
    },
    checkRecoverPass(){
    // function who calls API 'oauth2/confirm_forgot_password'
    // Send request to check if the code is true,
    // if is true, changes the passord, and ask to user to login again

        this.passwordRecoverVerify = true
        apiDefault.post(`oauth2/confirm_forgot_password`,{
          "tokencode":`${this.codeRecover}`,
          "username" : `${this.emailRecover}`,
          "newpassword":`${this.passwordRecover}`
        },{
          headers: {
            'accept': 'text/json',
            'Content-Type': 'application/json'
          }
        }
        ).then(resp => {
          let setUserForgot = resp.data;
          if(setUserForgot.statusCode == 404){
            this.recoverCodeErrorMsg = true
          }else{
            this.loadingAPI = false;
            this.loginEmail = this.emailRecover;
            this.loginPassword = this.passwordRecover;
            this.isForgot = false;
            this.isRecover = false;
            this.checkLogin();
          }
        }).catch(error => {
          this.isErrorFlag = true;
          this.errorTrigger = true;
          this.loadingAPI = false;
          console.log('erro: ', error)
        })
    },
    setLocalDataUser(){
    // function to set all the User's Data to be global

      const storeUserDataStore = useUserDataStore();
      const {setUserDataStore} = storeUserDataStore
      const dataUserEmailUser = this.getDataUser.emailUser;
      const dataUserNameUser = this.getDataUser.nameUser;
      const dataUserGroupsName = this.getDataUser.groups[0].groupName.toLowerCase()
      this.userGroupsName = this.getDataUser.groups[0].groupName.toLowerCase()
      // let dataUserGroupsNameFake = '';
      // if(this.getDataUser.groups[1]){
      //   dataUserGroupsNameFake = this.getDataUser.groups[1].groupName.toLowerCase()
      // }
      const configUserDataStore = {dataUserEmailUser, dataUserNameUser, dataUserGroupsName};
      setUserDataStore(configUserDataStore)

      localStorage.setItem("dataUserEmailUser", this.getDataUser.emailUser);
      localStorage.setItem("dataUserNameUser", this.getDataUser.nameUser);
      localStorage.setItem("dataUserIdUser", this.getDataUser.idUser);      
      localStorage.setItem("dataUserAccessToken", this.getDataUser.accessToken);
      localStorage.setItem("dataUserGroupsDescription", this.getDataUser.groups[0].groupDescription);
      localStorage.setItem("dataUserGroupsName", this.userGroupsName);
      // if(this.getDataUser.groups[1]){
      //   localStorage.setItem("dataUserGroupsDescriptionFake", this.getDataUser.groups[1].groupDescription);
      //   localStorage.setItem("dataUserGroupsNameFake", this.getDataUser.groups[1].groupName.toLowerCase());
      // }
      if (dataUserGroupsName == 'pof'){
        localStorage.setItem("dataUserGroupsMaster", true);
        this.getDataIfIdAPI();
      }else{
        this.$router.push('/');
      }
    },
    // Get Name && ID of the TPPIPs
		getDataIfIdAPI(){
    // function who calls API 'metrics/ifs'
    // IF its a 'POF' User, it get all the names and IDs from the IFs
			apiDefault.get(`/metrics/ifs`, {
				headers: {
					ifId: this.userGroupsName,
					authorization: 'Bearer '+this.getDataUser.accessToken
				}
			}).then(resp => {
				this.getIfId = resp.data;
        console.log(this.getIfId)
        localStorage.setItem("dataUserGroupsNameList", JSON.stringify(this.getIfId));
        this.$router.push('/');
			}).catch(error => {
				console.log('erro: ', error)
			})
		},
    checkPassMust(){
    // Function to check all the rules, setup by cognito to change the password.
      const regexUpper = new RegExp(/^(?=.*[A-Z]).+$/);
      const regexLower = new RegExp(/^(?=.*[a-z]).+$/);
      const regexNumber = new RegExp(/^(?=.*[0-9]).+$/);
      const regexSpecial = new RegExp(/^(?=.*[#?!@$%^&*-]).+$/);
      const regexLength = this.passwordRecover.length >= 8;
      
      if(this.passwordRecover.match(regexUpper)){
        this.mustPassUpper = true;
      }else{
        this.mustPassUpper = false;
      }
      if(this.passwordRecover.match(regexLower)){
        this.mustPassLower = true;
      }else{
        this.mustPassLower = false;
      }
      if(this.passwordRecover.match(regexNumber)){
        this.mustPassNumber = true;
      }else{
        this.mustPassNumber = false;
      }
      if(regexLength){
        this.mustPassLength = true;
      }else{
        this.mustPassLength = false;
      }
      if(this.passwordRecover.match(regexSpecial)){
        this.mustPassSpecial = true;
      }else{
        this.mustPassSpecial = false;
      }
      if(this.passwordRecover == this.passwordRecoverConfirm){
        this.mustPassMatch = true;
        this.passwordRecoverVerify = true;
      }else{
        this.mustPassMatch = false;
        this.passwordRecoverVerify = false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.loginUser{
  display: inline-flex;
  width: 100%;
  min-height: calc(100vh - 300px);
  .loadingBox, .errorPages{
    margin: auto;
    width: 90%;
    max-width: 720px;
  }
  .btnBack{
    top: 30px;
    left: 30px;
  }
  &__container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
    border-radius: $borderRadiusDefault;
    box-shadow: $shadow-default;
    color: $color-gray-darker;
    margin: auto;
    padding: 40px 100px;
    width: 90%;
    max-width: 720px;
    position: relative;
    h2, p, input, button{
      display: block;
      margin: 0 auto 20px;
      text-align: center;
    }
    h2{
      margin: 0 auto 30px;
    }
    label, p{
      display: block;
      color: $color-gray;
      font-size: 12px;
      line-height: 20px;
      font-weight: bold;
      margin: 0 auto;
      text-align: left;
      width: 90%;
      &.textForgot{
        cursor: pointer;
        font-size: 12px;
        text-align: left;
        width: 90%;
      }
    }
    p{
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 0 0 20px;
      width: 100%;
      font-weight: normal;
    }
    input{
      color: $color-gray;
      border: 1px solid $color-gray-light;
      border-radius: $borderRadiusSmall;
      height: 40px;
      font-size: 16px;
      line-height: 20px;
      padding-left: 15px;
      outline-color: $color-gray;
      text-align: left;
      width: 90%;
      &::placeholder{
        color: $color-gray-light;
      }
    }
    &--doubleLine{
      display: flex;
      justify-content: space-between;
      flex-flow: row-reverse;
    }
    button{
      margin: 20px auto 0;
    }
    &__login{
      display: block;
      width: 100%;
      max-width: 360px;
      margin: auto;
    }
    &__tokenAuth{
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;
      max-width: 360px;
      input{
        letter-spacing: 10px;
        text-align: center;
      }
      img{
        display: block;
        width: 290px;
        margin: 0 auto;
      }
      &--qrcode{
        max-width: unset;
        img{
          width: 245px;
        }
        #qrCodeImg{
          margin: auto;
          height: 200px !important;
          width: 200px !important;
          border: 1px solid $color-danger;
          border-radius: 10px;
          padding: 10px;
        }
      }
    }
    &__errorMsg{
      display: block;
      color: white;
      background-color: $color-danger;
      border-radius: $borderRadiusSmall;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      margin: -10px 0 15px;
      width: 100%;
    }
    &__recoverPassword{
      display: block;
      width: 100%;
      max-width: 360px;
      &--mustPass{
        width: 90%;
        margin: auto;
        p{
          display: flex;
          justify-content: flex-start;
          color: $color-danger;
          font-size: 12px;
          line-height: 20px;
          text-align: left;
          padding: 0;
          &.checkMustPass{
            color: $color-success;
          }
          img{
            display: block;
            margin: auto 5px auto 0;
          }
        }
      }
    }
  }
}
</style>