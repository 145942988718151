import axios from 'axios';

let getLocation = window.location.hostname;
let setEnviroment = '';
console.log(getLocation, "api")

// LOCALHOST e DEV API Enviroment
if(getLocation == ("localhost" || "portal-admin.exb.dev.sandbox-tecban.com.br")){
  setEnviroment = "https://api.admin-portal.hml.sandbox-tecban.com.br/";
}
// QA API Enviroment
else if(getLocation == "admin-portal-qa.hml.sandbox-tecban.com.br"){
  setEnviroment = "https://api-qa.admin-portal.hml.sandbox-tecban.com.br/";
}
// HML API Enviroment
else if(getLocation == "admin-portal.hml.sandbox-tecban.com.br"){
  setEnviroment = "https://api.admin-portal.hml.sandbox-tecban.com.br/";
}
// PROD API Enviroment
else if(getLocation == "portal.openfinance.sfa.tecban.com.br"){
  setEnviroment = "https://api-portal.openfinance.sfa.tecban.com.br/";
}



// API em PROD
//const apiDefault = axios.create({
//  baseURL:'https://api-portal.openfinance.sfa.tecban.com.br/',
//  'Content-Security-Policy': "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; // //object-src 'none'; frame-ancestors 'none'",
//  'Strict-Transport-Security': 'max-age=63072000',
//  'X-Content-Type-Options': 'nosniff',
//  'Cache-Control': 'no-cache'
//});

// API localHost, DEV, QA
//  baseURL:'https://api-qa.admin-portal.hml.sandbox-tecban.com.br/',

const apiDefaultLogin = axios.create({
  baseURL: setEnviroment,
  'Content-Security-Policy': "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; object-src 'none'; frame-ancestors 'none'",
  'Strict-Transport-Security': 'max-age=63072000',
  'X-Content-Type-Options': 'nosniff',
  'Cache-Control': 'no-cache'
});

export default apiDefaultLogin ;
