<template>
  <section class="averageTimeDashboard">
    <div class="averageTimeDashboard__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="goBackView()"
      />
      <h2>Tempo Médio de Resposta de chamadas de APIs</h2>
      <p class="averageTimeDashboard__container--description">
        Demonstração do tempo médio de resposta de chamada das APIs (em milissegundos), no período selecionado e agrupado por grupo de APIs e suas respectivas fases.
      </p>
      <div class="dateFilter">
        <p class="textDatePicker">Selecione um período</p>
        <div class="dateFilter__datapickerContent">
          <DatePickerCharts/>
          <ButtonShape
            class="buttonShape__Home"
            textButton="Filtrar"
            :bgButtonColor="bgButtonColor"
            @click="getDataApiAverageTime()"
          />
        </div>
        <span></span>
        <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
      </div>

      <LoadingBox v-show="loadingAPIByPeriod"/>
      <ErrorPages v-show="errorTriggerAPIByPeriod" :isError="isErrorFlagPeriod"/>
      <ChartVertical
        v-if="!errorTriggerAPIByPeriod"
        v-show="!loadingAPIByPeriod"
        :totalNumber="APITotalByPeriodMax"
        :barMultiColor="false"
        :barColor="barColorVert"
        :numVertical="totalAverageTimeApiByPeriod"
        :nameHorizontal="labelAverageTimeApiByPeriod"
        :milliSec="true"
        simbolVert="ms"
        :labelVertical="labelNumberAverageTimeApiByPeriodptBR"
      />
      
      <LoadingBox v-show="loadingAPIByPeriod"/>
      <ErrorPages v-show="errorTriggerAPIByName" :isError="isErrorFlagName"/>
      <ChartHorizontal
        v-if="!errorTriggerAPIByName"
        v-show="!loadingAPIByName"
        class="chartHorizontal"
        :totalNumber="APITotalByNameMax"
        :barColor="barColorHoriz"
        :numHorizontal="totalAverageTimeApiByName"
        :nameVertical="labelAverageTimeApiByName"
        :milliSec="true"
        simbolVert="ms"
        :labelHorizontal="labelNumberAverageTimeApiByNameptBR"
      />
    </div>
  </section>
</template>
<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ChartVertical from '@/components/ChartVertical.vue'
import ChartHorizontal from '@/components/ChartHorizontal.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'

export default {
  name: 'AverageTimeDashboard',
  props: {
    msg: String
  },
  data(){
    return {
      isErrorFlagPeriod: false,
      isErrorFlagName: false,
      errorTriggerAPIByPeriod: false,
      loadingAPIByPeriod: true,
      errorTriggerAPIByName: false,
      loadingAPIByName: true,

      barColorVert: colorsExported.colorChartYellow,
      barColorHoriz: [],
      bgButtonColor: colorsExported.colorPrimary,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',
      // BY PERIOD
      getAverageTimeApiByPeriod: [],
      setAverageTimeApiByPeriod: [],
      labelNumberAverageTimeApiByPeriodptBR: [],
      totalAverageTimeApiByPeriod: [],
      labelAverageTimeApiByPeriod: [],
      APITotalByPeriodMax: 0,
      // BY NAME
      getAverageTimeApiByName: [],
      setAverageTimeApiByName: [],
      totalAverageTimeApiByName: [],
      labelAverageTimeApiByName: [],
      APITotalByNameMax: 0,
      colorApiByPhase: [],
    }
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ChartVertical,
    ChartHorizontal,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApiAverageTime();
  },
  methods: {
    goBackView(){
      history.back();
    },
    getDataApiAverageTime() {      
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
    // setTimeout(()=>{
      this.getDataAPIByPeriod();
    // }, 2000);
      this.getDataAPIByName();
    },
    // BY PERIOD
    getDataAPIByPeriod(){
      this.loadingAPIByPeriod = true;
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/average-time/call-by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}/${this.granularityResults}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getAverageTimeApiByPeriod = resp.data;
        this.setAverageTimeApiByPeriod = this.getAverageTimeApiByPeriod.averageTimeByPeriod;
        this.loadingAPIByPeriod = false;
        if(this.setAverageTimeApiByPeriod.statusCode == 204 || this.setAverageTimeApiByPeriod == ''){
          this.isErrorFlagPeriod = false;
          this.errorTriggerAPIByPeriod = true;
        }else{
          this.errorTriggerAPIByPeriod = false;
          this.setAPIByPeriod();
        }
      }).catch(error => {
        this.isErrorFlagPeriod = true;
        this.errorTriggerAPIByPeriod = true;
        this.loadingAPIByPeriod = false;
        console.log('erro: ', error)
      })
    },
    setAPIByPeriod(){
      const byPeriodTotalptBR = []
      const byPeriodTotal = []
      const byPeriodLabel = []
      this.setAverageTimeApiByPeriod.forEach((itemByPeriod) =>{
          byPeriodTotalptBR.push(Number(itemByPeriod.averageTime.toFixed(1)).toLocaleString("pt-BR"));
          byPeriodTotal.push(itemByPeriod.averageTime.toFixed(1));
          byPeriodLabel.push(itemByPeriod.labelPeriod);
          this.labelNumberAverageTimeApiByPeriodptBR = byPeriodTotalptBR;
          this.totalAverageTimeApiByPeriod = byPeriodTotal;
          this.labelAverageTimeApiByPeriod = byPeriodLabel;
          this.APITotalByPeriodMax = Math.max(...this.totalAverageTimeApiByPeriod)*1.1;
        }
      );
    },
    // BY NAME
    getDataAPIByName(){
      this.loadingAPIByName = true;
      apiDefault.get(`/metrics/average-time/call-by-api-name/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getAverageTimeApiByName = resp.data;
        this.setAverageTimeApiByName = this.getAverageTimeApiByName.consumeApisByApi;
        this.loadingAPIByName = false;
        if(this.setAverageTimeApiByName.statusCode == 204 || this.setAverageTimeApiByName == ''){
          this.isErrorFlagName = false;
          this.errorTriggerAPIByName = true;
        }else{
          this.errorTriggerAPIByName = false;
          this.setAPIByName();
        }
      }).catch(error => {
        this.isErrorFlagName = true;
        this.errorTriggerAPIByName = true;
        this.loadingAPIByName = false;
        console.log('erro: ', error)
      })
    },
    setAPIByName(){
      const byNameTotalptBR = []
      const byNameTotal = []
      const byNameLabel = []
      const byPhase = []
      this.setAverageTimeApiByName.forEach((total) =>{
        byNameTotalptBR.push(Number(total.averageTime.toFixed(1)).toLocaleString("pt-BR"));
        byNameTotal.push(total.averageTime.toFixed(1));
        byNameLabel.push(total.apiName)
        byPhase.push(total.phase)
        this.labelNumberAverageTimeApiByNameptBR = byNameTotalptBR;
        this.totalAverageTimeApiByName = byNameTotal
        this.labelAverageTimeApiByName = byNameLabel
        this.APITotalByNameMax = Math.max(...this.totalAverageTimeApiByName)*1.1;
        this.colorApiByPhase = byPhase
        this.setPhase();
      });
    },
    setPhase(){
      const arraybyphase = [];
      this.colorApiByPhase.forEach(colorPhase => {
        switch(colorPhase){
          case '0': 
            arraybyphase.push(colorsExported.colorChartYellow);
            break;
          case '1': 
            arraybyphase.push(colorsExported.colorChartPurple);
            break;
          case '2': 
            arraybyphase.push(colorsExported.colorChartGreen);
            break;
          case '3': 
            arraybyphase.push(colorsExported.colorChartPurpleDark);
            break;
          case '4A': 
            arraybyphase.push(colorsExported.colorChartRed);
            break;
          default:
            arraybyphase.push(colorsExported.colorChartYellow);
            break;
        }
        this.barColorHoriz = arraybyphase;
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.averageTimeDashboard{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
    .chartVertical{
      border-bottom: 1px solid $color-chart-line;
      padding: 0 0 20px;
      margin: 0 0 20px;
    }
  }
}
</style>