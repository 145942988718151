<template>
  <section class="profilePage">
    <div class="profilePage__title">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="goBackView()"
      />
      <h2>Dados do Perfil</h2>
      <!-- <p class="profilePage__title--description">
        Dados do perfil.
      </p> -->
    </div>
    <ul class="profilePage__listData">
      <li>
        <p><strong>Nome: </strong>{{userName}}</p>
      </li>
      <li>
        <p><strong>e-mail: </strong>{{userEmail}}</p>
      </li>
      <li>
        <p><strong>Identificador: </strong>{{userGroupsName}}</p>
      </li>
      <li>
        <p><strong>Descrição: </strong>{{userGroupsDescription}}</p>
      </li>
    </ul>
  </section>
</template>
<script>
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
export default {
  name: 'ProfilePage',
  data() {
    return {
      userName: '',
      userEmail: '',
      userGroupsName:'',
      userGroupsDescription:'',
    }
  },
  components: {
    ButtonArrowCircle,
  },
  mounted() {
    this.userName = localStorage.getItem("dataUserNameUser");
    this.userEmail = localStorage.getItem("dataUserEmailUser");
    if(localStorage.getItem("dataUserGroupsNameFake")){
      this.userGroupsName = localStorage.getItem("dataUserGroupsNameFake");
      this.userGroupsDescription = localStorage.getItem("dataUserGroupsDescriptionFake");
    }else{
      this.userGroupsName = localStorage.getItem("dataUserGroupsName");
      this.userGroupsDescription = localStorage.getItem("dataUserGroupsDescription");
    }
  },
  methods: {
    goBackView(){
      history.back();
    },
  },
}
</script>
<style lang="scss" scoped>
.profilePage{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__title{
    padding: 0 50px 20px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
      span{
        font-weight: 400;
      }
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
  }
  &__listData{
    display: flex;
    justify-content: left;
    flex-flow: column;
    margin: 0 5%;
    li{
      margin: 20px 0;
      p{
        text-align: left;
      }
    }
  }
}
</style>
