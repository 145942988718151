<template>
  <button :disabled="btnDisabled" class="buttonShape"
          :style="[classLine ? `border: 2px solid ${bgButtonColor}; 
                                color: ${bgButtonColor};
                                background-color: unset;`
                             : `background-color:${bgButtonColor}`]">
    {{textButton}}
  </button>
</template>

<script>
export default {
  name: 'ButtonShape',
  props: {
    textButton: {
      type: String,
      required: true
    },
    bgButtonColor: {
      type: String,
      required: true
    },
    classLine: Boolean,
    btnDisabled: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.buttonShape{
  cursor: pointer;
  color: white;
  border-radius: $borderRadiusDefault;
  border: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 50px;
}
</style>