<template>
  <section class="logsPage">
    <div class="logsPage__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="goBackView()"
      />
      <h2>Logs da API</h2>
      <p class="logsPage__container--description">
        Defina as condições de filtro para os logs da API
      </p>
      <form @submit.prevent="getDataApi()" class="logsPage__searchInterID">
        <p>Pesquisar por Interacion ID</p>
        <input autofocus type="text" v-model="setInteractionID" name="interactionID" required >
        <ButtonShape
          textButton="PESQUISAR"
          :bgButtonColor="bgButtonColor"
          @click="prevent"
        />
      </form>
      <LoadingBox v-show="loadingAPI" v-if="!showSeachId"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div class="logsPage__Logs" v-if="!errorTrigger" v-show="!loadingAPI">
        <!-- Seach Result true -->
        <span v-if="searchInterId">
          Mostrando log para o Interaction ID : <strong>{{ setInteractionID }}</strong>
        </span>
        <div class="logsPage__Logs--content" v-if="searchInterId">
          {{ setLogsPageAPI }}
        </div>
        <!-- Search Result False or Null -->
        <span class="searchError" v-if="!searchInterId">
          Interaction ID não encontrado: <strong>{{ setInteractionID }}</strong>
        </span>
        <div class="logsPage__Logs--content searchErrorImg" v-if="!searchInterId">
          <img src="@/assets/images/icon_warning.svg" alt="Interection ID não existe">
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'

export default {
  name: 'LogsPage',
  props: {

  },
  data(){
    return {
      showSeachId: true,
      loadingAPI: true,
      isErrorFlag: false,
      errorTrigger: false,
      bgButtonColor: colorsExported.colorPrimary,

      dataProfileIfName: '',
      dataUserAccessToken: '',

      setInteractionID: '',
      setLogsPageAPI: [],
      searchInterId: true,
    }
  },
  components: {
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  methods: {
    goBackView(){
      history.back();
    },
    setLoadingErrorDefault(){
      this.showSeachId = false;
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      setTimeout(() => {
        this.getDataLogPageAPI();
      }, '1000');
    },
    // Get Data API
    getDataLogPageAPI(){
      // teste ID AMZ = d9d56a1d-0c44-4fb5-a24a-68c44d474097
      apiDefault.get(`/metrics/log/by-interaction/${this.setInteractionID}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getLogPageAPI = resp.data;
        this.loadingAPI = false;
        if(this.getLogPageAPI == 204){
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else if(this.getLogPageAPI == ''){
          this.searchInterId = false;
        }else{
          this.searchInterId = true;
          let jasonString = JSON.stringify(this.getLogPageAPI[0], null , "\t");
          this.setLogsPageAPI = jasonString.replace(/, /g, "/t")
          console.log('JSON', this.setLogsPageAPI)
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.logsPage{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
  }
  &__searchInterID{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color-gray-lighter;
    border-bottom: 1px solid $color-gray-lighter;
    padding: 20px 0;
    p{
      font-size: 12px;
      font-weight: 700;
      width: 16%;
      margin: auto 0;
    }
    input{
      color: $color-gray;
      border: 1px solid $color-gray-light;
      border-radius: 6px;
      height: 40px;
      font-size: 16px;
      line-height: 20px;
      padding-left: 15px;
      outline-color: $color-gray;
      text-align: left;
      width: 90%;
      &::placeholder{
        color: $color-gray-light;
      }
    }
    button{
      margin: 0 10px;
    }
  }
  &__Logs{
    margin: 20px 0;
    span{
      display: block;
      background-color: $color-info;
      border-radius: 8px;
      color: white;
      padding: 15px 30px;
      width: 100%;
    }
    &--content{
      background-color: $color-gray-blue;
      margin: 20px 0;
      padding: 20px;
      overflow-y: scroll;
      height: 50vh;
    }
  }
  .searchError{
    background-color: $color-danger;
    &Img{
      height: auto;
      overflow: unset;
      img{
        display: block;
        margin: 50px auto;
      }
    }
  }
}
</style>
