<template>
	<footer>
		<div class="contentFooter">
			<img src="@/assets/images/logo_tecban_footer.svg" alt="logo TecBan">
			<img src="@/assets/images/logo_openFinance_footer.svg" alt="logo Open Finance">
		</div>
	</footer>
</template>
<style lang="scss" scoped>
footer{
	background-color: $color-gray-darker;
	margin: 0 auto;
	height: 100px;
	width: 100%;
	.contentFooter{
		display: flex;
		justify-content: space-between;
		width: 90%;
		max-width: 1100px;
		height: 100%;
		margin: 0 auto;
		position: relative;
		img{
			display: block;
			margin: 0;
			width: 140px;
		}
	}
}
</style>
