import {createRouter, createWebHistory} from "vue-router";

import LoginUser from '@/pages/LoginUser';
import HomeDashboard from '@/pages/HomeDashboard';
import ChartsMenu from '@/pages/ChartsMenu';
import SuccessDashboard from '@/pages/chartsMenu/SuccessDashboard';
import FailDashboard from '@/pages/chartsMenu/FailDashboard';
import AvailableDashboard from '@/pages/chartsMenu/AvailableDashboard';
import AverageTimeDashboard from '@/pages/chartsMenu/AverageTimeDashboard';
import ConsumedAPIsDashboard from '@/pages/chartsMenu/ConsumedAPIsDashboard';
import ConsumedIPagDashboard from '@/pages/chartsMenu/ConsumedIPagDashboard';
import ConsentDashboard from '@/pages/chartsMenu/ConsentDashboard';
import TransactionPIX from '@/pages/chartsMenu/TransactionPIX';
import PaymentInitiator from '@/pages/PaymentInitiator';
import LogsPage from '@/pages/LogsPage';
import CertificatePage from '@/pages/CertificatePage';
import CertificateDetail from '@/pages/CertificateDetail';
import RegulatoryReport from '@/pages/RegulatoryReport';
import ProfilePage from '@/pages/ProfilePage';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path:'/login',
      name: 'LoginUserCognito',
      component: LoginUser,
    },
    {
      path:'/',
      name: 'HomeDashboard',
      component: HomeDashboard,
    },
    {
      path:'/dados-perfil',
      name: 'ProfilePage',
      component: ProfilePage,
    },
    {
      path:'/indicadores-graficos',
      name: 'ChartsMenu',
      component: ChartsMenu,
    },
    {
      path:'/iniciadoras-pagamentos',
      name: 'PaymentInitiator',
      component: PaymentInitiator,
    },
    {
      path:'/logs-api',
      name: 'LogsPage',
      component: LogsPage,
    },
    {
      path:'/certificados',
      name: 'CertificatePage',
      component: CertificatePage,
    },
    {
      path:'/certificados/detalhes-certificado',
      name: 'CertificateDetail',
      component: CertificateDetail,
    },
    {
      path:'/relatorio-regulatorio',
      name: 'RegulatoryReport',
      component: RegulatoryReport,
    },
    {
      path:'/chamadas-api-sucesso',
      name: 'SuccessDashboard',
      component: SuccessDashboard,
    },
    {
      path:'/chamadas-api-falha',
      name: 'FailDashboard',
      component: FailDashboard,
    },
    {
      path:'/chamadas-api-disponibilidade',
      name: 'AvailableDashboard',
      component: AvailableDashboard,
    },
    {
      path:'/chamadas-api-tempo_medio',
      name: 'AverageTimeDashboard',
      component: AverageTimeDashboard,
    },
    {
      path:'/volumetria-api',
      name: 'ConsumedAPIsDashboard',
      component: ConsumedAPIsDashboard,
    },
    {
      path:'/volumetria-api-iniciadora-pagamento',
      name: 'ConsumedIPagDashboard',
      component: ConsumedIPagDashboard,
    },
    {
      path:'/chamadas-api-consentimento',
      name: 'ConsentDashboard',
      component: ConsentDashboard,
    },
    {
      path:'/transacoes-pix',
      name: 'TransactionPIX',
      component: TransactionPIX,
    },
  ],
});

export default router;