<template>
  <section class="failDashboard">
    <div class="failDashboard__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="goBackView()"
      />
      <h2>Chamadas de  APIs com falha</h2>
      <p class="failDashboard__container--description">
        Demonstração de quantidade das chamadas de APIs que retornaram com falha no período selecionado e agrupado por grupo de APIs e suas respectivas fases.
      </p>
      <div class="dateFilter">
        <p class="textDatePicker">Selecione um período</p>
        <div class="dateFilter__datapickerContent">
          <DatePickerCharts/>
          <ButtonShape
            class="buttonShape__Home"
            textButton="Filtrar"
            :bgButtonColor="bgButtonColor"
            @click="getDataApiFail()"
          />
        </div>
        <span></span>
        <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
      </div>

      <LoadingBox v-show="loadingAPIByPeriod"/>
      <ErrorPages v-show="errorTriggerAPIByPeriod" :isError="isErrorFlagByPeriod"/>
      <ChartVertical
        v-if="!errorTriggerAPIByPeriod"
        v-show="!loadingAPIByPeriod"
        :totalNumber="APITotalByPeriodMax"
        :barMultiColor="false"
        :barColor="barColorVert"
        :numVertical="totalFailApiByPeriod"
        :nameHorizontal="labelFailApiByPeriod"
      />
      
      <LoadingBox v-show="loadingAPIByName"/>
      <ErrorPages v-show="errorTriggerAPIByName" :isError="isErrorFlagByName"/>
      <ChartHorizontal
        v-if="!errorTriggerAPIByName"
        v-show="!loadingAPIByName"
        class="chartHorizontal"
        :totalNumber="APITotalByName"
        :barColor="barColorHoriz"
        :numHorizontal="totalFailApiByName"
        :nameVertical="labelFailApiByName"
      />
    </div>
  </section>
</template>
<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ChartVertical from '@/components/ChartVertical.vue'
import ChartHorizontal from '@/components/ChartHorizontal.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'
import { useUserDataStore } from '@/stores/UserStore'

export default {
  name: 'FailDashboard',
  props: {
    msg: String
  },
  data(){
    return {
      loadingAPIByPeriod: true,
      loadingAPIByName: true,
      errorTriggerAPIByPeriod: false,
      errorTriggerAPIByName: false,
      isErrorFlagByPeriod: false,
      isErrorFlagByName: false,

      barColorVert: colorsExported.colorChartRed,
      barColorHoriz: [],
      bgButtonColor: colorsExported.colorPrimary,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',
      // BY PERIOD
      getFailApiByPeriod: [],
      setFailApiByPeriod: [],
      totalFailApiByPeriod: [],
      labelFailApiByPeriod: [],
      APITotalByPeriodMax: 0,
      // BY NAME
      getFailApiByName: [],
      setFailApiByName: [],
      totalFailApiByName: [],
      labelFailApiByName: [],
      APITotalByNameMax: 0,
      APITotalByName: 0,
      colorApiByPhase: [],

      //Pinia
    }
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ChartVertical,
    ChartHorizontal,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApiFail();
  },
  methods: {
    goBackView(){
      history.back();
    },
    setLoadingErrorDefault(){
      this.loadingAPIByPeriod = true;
      this.loadingAPIByName = true;
      this.errorTriggerAPIByPeriod = false;
      this.errorTriggerAPIByName = false;
      this.isErrorFlagByPeriod = false;
      this.isErrorFlagByName = false;
    },
    getDataApiFail() {
      this.setLoadingErrorDefault();
      
      const storeUserDataStore = useUserDataStore();
      const {setUserDataStore} = storeUserDataStore;
      console.log(setUserDataStore, storeUserDataStore)


      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      this.getDataAPIByPeriod();
      this.getDataAPIByName();
    },
    // BY PERIOD
    getDataAPIByPeriod(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/fail/call-by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}/${this.granularityResults}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getFailApiByPeriod = resp.data;
        this.setFailApiByPeriod = this.getFailApiByPeriod.failByPeriod;
        if(this.getFailApiByPeriod.statusCode == 204 || this.setFailApiByPeriod == ''){
          this.loadingAPIByPeriod = false;
          this.isErrorFlagByPeriod = false;
          this.errorTriggerAPIByPeriod = true;
        }else{
          this.errorTriggerAPIByPeriod = false;
          this.setAPIByPeriod();
        }
      }).catch(error => {
        this.isErrorFlagByPeriod = true;
        this.errorTriggerAPIByPeriod = true;
        this.loadingAPIByPeriod = false;
        console.log('erro: ', error)
      })
    },
    setAPIByPeriod(){
      this.loadingAPIByPeriod = false;
      const byPeriodTotal = []
      const byPeriodLabel = []
      this.setFailApiByPeriod.forEach((itemByPeriod) =>{
          byPeriodTotal.push(itemByPeriod.totalRequest)
          byPeriodLabel.push(itemByPeriod.labelPeriod)
          this.totalFailApiByPeriod = byPeriodTotal
          this.labelFailApiByPeriod = byPeriodLabel
          this.APITotalByPeriodMax = Math.max(...byPeriodTotal)*1.1;
        }
      );
    },
    // BY NAME
    getDataAPIByName(){
      apiDefault.get(`/metrics/fail/call-by-api-name/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getFailApiByName = resp.data;
        this.setFailApiByName = this.getFailApiByName.consumeApisByApi;
        if(this.setFailApiByName.statusCode == 204 || this.setFailApiByName == ''){
          this.loadingAPIByName = false;
          this.isErrorFlagByName = false;
          this.errorTriggerAPIByName = true;
        }else{
          this.errorTriggerAPIByName = false;
          this.setAPIByName();
        }
      }).catch(error => {
        this.isErrorFlagByName = true;
        this.errorTriggerAPIByName = true;
        this.loadingAPIByName = false;
        console.log('erro: ', error)
      })
    },
    setAPIByName(){
      this.loadingAPIByName = false;
      const byNameTotal = []
      const byNameLabel = []
      const byPhase = []
      this.setFailApiByName.forEach((total) =>{
          byNameTotal.push(total.totalRequest)
          byNameLabel.push(total.apiName)
          byPhase.push(total.phase)
          this.totalFailApiByName = byNameTotal
          this.labelFailApiByName = byNameLabel
          this.APITotalByNameMax = Math.max(...byNameTotal)*1.1;
          this.colorApiByPhase = byPhase
          let APITotalByNameArray = 0;
          for(var i = 0; i < byNameTotal.length; i++) {
              APITotalByNameArray += byNameTotal[i];
          }
          this.APITotalByName = APITotalByNameArray
          this.setPhase();
        }
      );
    },
    setPhase(){
      const arraybyphase = [];
      this.colorApiByPhase.forEach(colorPhase => {
        switch(colorPhase){
          case '0': 
            arraybyphase.push(colorsExported.colorChartYellow);
            break;
          case '1': 
            arraybyphase.push(colorsExported.colorChartPurple);
            break;
          case '2': 
            arraybyphase.push(colorsExported.colorChartGreen);
            break;
          case '3': 
            arraybyphase.push(colorsExported.colorChartPurpleDark);
            break;
          case '4A': 
            arraybyphase.push(colorsExported.colorChartRed);
            break;
          default:
            arraybyphase.push(colorsExported.colorChartYellow);
            break;
        }
        this.barColorHoriz = arraybyphase;
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.failDashboard{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
    .chartVertical{
      border-bottom: 1px solid $color-chart-line;
      padding: 0 0 20px;
      margin: 0 0 20px;
    }
  }
}
</style>
